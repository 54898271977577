module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"themuffintops","short_name":"tmt","start_url":"/","background_color":"transparent","theme_color":"#1b2727","display":"minimal-ui","icon":"src/assets/favicon/the-muffin-tops-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"bdda7c4deec3b14ad8f1ebcbad3c316a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://matomo.danielkoch.de","siteUrl":"https://themuffintops.de","disableCookies":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
