/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export const onServiceWorkerUpdateReady = () => {
	// eslint-disable-next-line no-alert, no-useless-concat
	const answer = window.confirm(`Freshe Information der Muffin Tops gefällig? ` + `Neuladen, bitte!`);

	if (answer === true) {
		window.location.reload();
	}
};
